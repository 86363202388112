/**
 * Javascript for InjectorNewsletterSignup
 **/
import {
  doSubscribeAjax,
  isFormValid,
} from "../../../../gu/assets/js/newsletter/util"; // TODO: Move util.js to pubstack

function InjectorNewsletterSignup() {
  this.fifthParagraph = (article, widget) => {
    if (pCount >= 6) {
      let middle = Math.ceil(pCount / 2);
      // Add some separation between ads using "everyThird"
      if (middle % 3 === 0) {
        middle++;
      }
      mainContent.children("p:eq(" + middle + ")").before(widget);
    }

    const mainContent = article.find('[data-js="main-content"]');
    const pCount = mainContent.children("p:not(:empty)").length;
    const galleryCount = mainContent.find(".gallery-slide").length;
    const commerceCount = mainContent.find(".commerce-gallery-item").length;
    const slideCount = galleryCount ? galleryCount : commerceCount; // Either gallery or commerce
    // After 5rd Paragraph or 75% of article, whichever is HIGHER.
    const mid = Math.max(5, Math.floor(((pCount + slideCount) * 3) / 4));
    if (pCount >= mid) {
      mainContent.children("p:eq(" + (mid - 1) + ")").after(widget);
    } else {
      mainContent
        .find(
          `.${galleryCount ? "gallery-slide" : "commerce-gallery-item"}:eq(${
            mid - pCount - 1
          })`
        )
        .after(widget);
    }

    const section = mainContent.find(".newsletter-subscribe-inline");
    section.slideDown();
    this.register(section);
  };
  this.register = (section) => {
    section.find(".js-dismiss").on("click", () => this.close(section));
    section.find(".js-next").on("click", (e) => this.nextStep(e));
    section
      .find(".subscribe-form")
      .on("submit", (e) => this.submit(e, section));
  };

  this.close = (section) => {
    sessionStorage.setItem("ess_nl_inline_displayed", "true");
    section.slideUp(1000, function () {
      section.remove();
    });
  };

  this.nextStep = (e) => {
    e.preventDefault();

    const $step = $(e.target).closest(".js-step");
    if (isFormValid($step, false)) {
      $step.hide();
      $step.next().fadeIn(500);
    }
  };

  this.submit = (e, section) => {
    e.preventDefault();

    const $form = $(e.target);

    if (isFormValid($form)) {
      doSubscribeAjax($form, () => {
        // Article inline form has two steps
        if ("subscribe-next-step" === $form.data("mode")) {
          // pre-populate email address from previous step
          $("#subscribe-step2 input[type=email]").val(
            $("#subscribe-step1 input[type=email]").val()
          );
          this.nextStep(e);
        } else {
          const scrollTop =
            section.offset().top - $("#masthead").outerHeight() - 100;

          $("html, body").animate({ scrollTop }, 500);
          setTimeout(() => this.close(section), 3000);
        }
      });
    }
  };
}

export default InjectorNewsletterSignup;
